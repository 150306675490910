import React, { useRef } from 'react';

import { Tab } from 'react-bootstrap';

import CsvUpload from '../../components/CsvUpload';

function Tabcontent({ setFile, setSearchInput }) {

	const inputRef = useRef(null); // Create a ref for the input

	const handleClick = event => {
		event.preventDefault(); // Prevent form submission
		if (inputRef.current) {
			setSearchInput(inputRef.current.value); // Update the search input state
		}
	};

	return (
		<div >

			<Tab.Container defaultActiveKey="Place" className="dlab-tabs search-filter">
				<Tab.Content>
					<Tab.Pane eventKey="Place" className="tab-pane">
						<form>
							<div className="input-group">
								<input ref={inputRef} type="text" className="form-control" placeholder="Enter a LEGO® ID; e.g. 22885" />
								<div className="input-group-prepend mr-2">
									<button className="site-button text-uppercase" onClick={handleClick}>
										<i className="fa m-r5 fa-search"></i> Search
									</button>
								</div>
								<CsvUpload setFile={setFile} />
							</div>
						</form>
					</Tab.Pane>
				</Tab.Content>
				<p className="text-center text-white m-b10 m-t30">Use Rebrickable .csv or LEGO® .ldr or Bricklink .xml to Order Gobricks in minute! (BETA)</p>
			</Tab.Container>
		</div>
	)


}

export default Tabcontent;