import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import swal from "sweetalert";

import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import TabserviceMocBrick from './../Element/TabserviceMocBrick';

import Tabcontent from './../Element/Tabcontent';

var bnr = require('./../../images/main-slider/slide6.jpg');

class Homepage extends Component {

	state = {
		csvFile: '',
		fileName: '',
		colorType: '',
		searchInput: '',
		activeTab: 'Search'
	};

	setActiveTab = (tab) => {
		this.setState({ activeTab: tab });
	};

	setFile = async (file) => {
		if (!file.name.endsWith('.csv') && !file.name.endsWith('.ldr') && !file.name.endsWith('.xml')) {
			swal("Oops", "Please upload a CSV or ldr or xml file", "error");
			return;
		}
		const size = file.size;
		if (size > 1 * 1024 * 1024) { // 1 MB in bytes
			swal("Oops", "File is too large. Maximum allowed file size is 1 MB.", "error");
			return;
		}
		const reader = new FileReader();
		const fileContents = await new Promise((resolve, reject) => {
			reader.onload = (e) => {
				resolve(e.target.result);
			};
			reader.onerror = (err) => {
				reject(err);
			};
			reader.readAsText(file);
		});

		if (file.name.endsWith('.csv')) {
			const rows = fileContents.split('\n');
			//console.log('rows count' + rows.length);
			if (rows && rows.length <= 1000) {
				this.setState({
					csvFile: fileContents,
					fileName: file.name,
					colorType: 'io',
					activeTab: 'Csv_gobrick',
				});
			} else {
				swal("Oops", "Maximum parts in inventory is 1000.", "error");
			}
		} else if (file.name.endsWith('.ldr')) {
			const lines = fileContents.split('\n');
			const parts = {};

			for (let line of lines) {
				line = line.trim(); // Trim any extra spaces or newline characters
				if (line.startsWith('1 ') && line.includes('.dat')) { // Line describing a part
					const partsOfLine = line.split(' ');
					const colorId = partsOfLine[1];
					const partId = partsOfLine[partsOfLine.length - 1].replace('.dat', '');

					const key = `${partId},${colorId}`;
					parts[key] = (parts[key] || 0) + 1;
				}
			}

			const formattedParts = Object.entries(parts).map(([key, quantity]) => `${key},${quantity}`);
			//console.log('formattedParts count' + formattedParts.length);
			if (formattedParts && formattedParts.length <= 1000) {
				const header = "Part,Color,Quantity";
				const csvString = [header, ...formattedParts].join('\n');
				this.setState({
					csvFile: csvString, // Store the parsed LDR data as a CSV string
					fileName: file.name,
					colorType: 'io',
					activeTab: 'Csv_gobrick',
				});
			} else {
				swal("Oops", "Maximum parts in inventory is 1000.", "error");
			}

		} else if (file.name.endsWith('.xml')) {
			// New code for handling XML files
			try {
				const parser = new DOMParser();
				const xmlDoc = parser.parseFromString(fileContents, "text/xml");
				const items = xmlDoc.getElementsByTagName("ITEM");
				const parts = [];

				for (let item of items) {
					const itemIdElement = item.getElementsByTagName("ITEMID")[0];
					const colorElement = item.getElementsByTagName("COLOR")[0];
					const minQtyElement = item.getElementsByTagName("MINQTY")[0];

					if (itemIdElement && colorElement && minQtyElement) {
						const partId = itemIdElement.textContent;
						const colorId = colorElement.textContent;
						const quantity = minQtyElement.textContent;

						parts.push(`${partId},${colorId},${quantity}`);
					}
				}
				//console.log('parts count' + parts.length);
				if (parts && parts.length <= 1000) {
					const csvString = "partId,colorId,quantity\n" + parts.join('\n');
					this.setState({
						csvFile: csvString,
						fileName: file.name,
						colorType: 'LDraw',
						activeTab: 'Csv_gobrick',
					});
				} else {
					swal("Oops", "Maximum parts in inventory is 1000.", "error");
				}

			} catch (error) {
				//console.error("Error parsing XML file: ", error);
				swal("Error", "Failed to parse XML file.", "error");
			}
		}
	};

	setSearchInput = (input) => {
		this.setState({ searchInput: input, activeTab: 'Search' });
	}

	setSearchResult = (json) => {

	};

	render() {
		return (

			<div className="page-wraper">
				<Header />

				<div className="page-content bg-white">

					<div className="dlab-bnr-inr dlab-bnr-inr-md" style={{ backgroundImage: "url(" + bnr + ")", backgroundSize: "cover" }}>
						<div className="container">
							<div className="align-m dlab-home">
								<div className="bnr-content">
									<h2><strong>Find & Get</strong> Your Ideal Brick</h2>
								</div>

								<div className="dlab-tabs search-filter">
									<Tabcontent setFile={this.setFile} setSearchInput={this.setSearchInput} />
								</div>
							</div>
						</div>
					</div>

					<div className="content-block">
						<pre></pre>
						<TabserviceMocBrick
							searchInput={this.state.searchInput}
							setSearchResult={this.setSearchResult}
							activeTab={this.state.activeTab}
							setActiveTab={this.setActiveTab}
							csvFile={this.state.csvFile}
							fileName={this.state.fileName}
							colorType={this.state.colorType}
						/>

					</div>

					<div className="content-block">
						<div className="section-full bg-img-fix bg-white content-inner">
							<div className="container">
								<div className="section-head text-center">
									<h2 className="box-title">How Toolbox Works?</h2>
									<div className="dlab-separator bg-primary"></div>
									<p>Explore the Vast Collection of Custom LEGO Creations (MOCs). Whether You're Seeking Iconic LEGO-Compatible Bricks, Custom LEGO Sets.</p>
								</div>
								<div className="row">

									<div className="col-lg-4 col-md-6">
										<div className="icon-bx-wraper center work-box style1 m-b30">
											<div className="box-count">01</div>
											<div className="icon-bx-lg radius bg-gray-1 m-b20">
												<Link to={"#"} className="icon-cell"><i className="ti-search text-primary"></i></Link>
											</div>
											<div className="icon-content">
												<h3 className="dlab-tilte">Find What MOC Your Want</h3>
												<p>1.1. Go to https://rebrickable.com/; </p>
												<p>1.2. Search a MOC or Lego Set, Select it;</p>
												<p>1.3. From "Inventory" -{'>'} "Export Parts" -{'>'} "Rebrickable CSV"</p>
												<p>1.4. CSV file will donwloaded.</p>
											</div>
										</div>
									</div>

									<div className="col-lg-4 col-md-6">
										<div className="icon-bx-wraper center work-box style1 m-b30">
											<div className="box-count">02</div>
											<div className="icon-bx-lg radius bg-gray-1 m-b20">
												<Link to={'#'} className="icon-cell"><i className="ti-gift text-primary"></i></Link>
											</div>
											<div className="icon-content">
												<h3 className="dlab-tilte">Choose the brick parts</h3>
												<p>2.1. Back to our Website </p>
												<p>2.2. "Search" a Lego by ID </p>
												<p>2.3. "Add" a Brick part</p>
												<p>2.4. "Upload CSV", file dowloaded from Rebrickable.</p>
											</div>
										</div>
									</div>
									<div className="col-lg-4 col-md-6">
										<div className="icon-bx-wraper center work-box style1 m-b30">
											<div className="box-count">03</div>
											<div className="icon-bx-lg radius bg-gray-1 m-b20">
												<Link to={'#'} className="icon-cell"><i className="ti-rocket text-primary"></i></Link>
											</div>
											<div className="icon-content">
												<h3 className="dlab-tilte">Make an order</h3>
												<p>3.1. Review "Total in Cart"</p>
												<p>3.2. Click "Checkout button"</p>
												<p>3.3. Fill in payment&shipping detail</p>
												<p>3.4. Order will Process and direct from Gobricks Manufacturing.</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>


				</div>

				<Footer />

			</div>


		)
	}

}

export default Homepage;