import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';

//Context
import { useCart } from '../../contexts/CartContext';

//Import graphql
import graphqlClient from '../../graphql/index';
import { GET_GOBRICKS } from "../../graphql/gobricksQueries";
import ReactGA from 'react-ga4';
//Import LoadingOverlay 
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader'
//Import Env
import { DEBUG } from '../../env';

const numberOfItemPerPage = 100;

function BrickSearchResult({ inputFromSearch, setActiveTab }) {

    const [currentPage, setCurrentPage] = useState(1);
    const [limit] = useState(numberOfItemPerPage);
    const [totalPage, setTotalPage] = useState(1);
    const [gobricks, setGobricks] = useState(null);
    const [searchInput, setSearchInput] = useState("");
    const [loading, setLoading] = useState(false);

    const { addAllFoundBricks } = useCart();

    const getGobricks = useCallback(async (page) => {

        ReactGA.event({
            category: 'User',
            action: 'Search' + inputFromSearch
        });

        if (inputFromSearch.length < 3 || searchInput === inputFromSearch) {
            return;
        }

        setSearchInput(inputFromSearch);

        if (DEBUG() === true) console.log('GET_GOBRICKS inputFromSearch:' + inputFromSearch + ' page:' + page);
        setLoading(true);
        await graphqlClient
            .query({
                query: GET_GOBRICKS,
                variables: { search: inputFromSearch, page: page, limit: limit },
                fetchPolicy: "no-cache"
            })
            .then(result => {
                setLoading(false);
                if (result.errors && result.errors.length > 0) {
                    const errMessage = result.errors[0].message;
                    addAllFoundBricks(null);
                    if (DEBUG() === true) console.log("err" + errMessage);
                } else {
                    if (DEBUG() === true) console.log("getGobricks result currentPage:" + result.data.GetGobricks.currentPage + ' totalPages:' + result.data.GetGobricks.totalPages);
                    if (result.data.GetGobricks.gobricks) {
                        setCurrentPage(result.data.GetGobricks.currentPage);
                        setTotalPage(result.data.GetGobricks.totalPages);
                        setGobricks(result.data.GetGobricks.gobricks);

                        addAllFoundBricks(result.data.GetGobricks.gobricks);
                    } else {
                        setGobricks(null);
                        addAllFoundBricks(null);
                        setCurrentPage(1);
                        setTotalPage(1);
                    }
                }
            })
            .catch(err => {
                setLoading(false);
                if (DEBUG() === true) console.log("err" + err);
                addAllFoundBricks(null);
            });
    }, [addAllFoundBricks, inputFromSearch, limit, searchInput]);

    const { addToCart } = useCart();

    const handleAddToCart = (item) => {
        addToCart(item, "search");
        setActiveTab("Cart");
    };

    // Effect Hook Similar to componentDidMount and componentDidUpdate
    useEffect(() => {
        getGobricks(currentPage);
    }, [getGobricks, currentPage]);

    return (
        <LoadingOverlay
            active={(loading === true)} spinner={<BounceLoader />} >
            {loading ? (
                // Display loading indicator
                <div className="content-block">
                    <div className="section-full bg-img-fix bg-white content-inner">
                        <div className="container">
                            <div className="section-head text-center">
                                <h2 className="box-title">Loading...</h2>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <React.Fragment>
                    <div className="container">

                        <div className="row">
                            {/* Check if gobricks exists before mapping */}
                            {gobricks && gobricks.map((item, index) => (
                                <div className="col-lg-2 col-md-4 col-sm-4 col-6 m-b30" key={index}>
                                    <div className="listing-bx overlap">
                                        <div className="listing-media">
                                            {/* Use image_src from gobricks for the image */}
                                            <img
                                                src={item.image_src}
                                                alt=""
                                                onError={(e) => { e.target.onerror = null; e.target.src = 'https://moc-parts.web.app/gobricks/images/no_image.png'; }}
                                            />
                                        </div>
                                        <div className="listing-info">
                                            {!item.lego_id.includes("nan") && (
                                                <p>
                                                    <strong>{item.lego_id}</strong>
                                                </p>
                                            )}
                                            {item.external_ids && item.external_ids.LEGO && item.external_ids.LEGO.length > 0 && (
                                                <p>
                                                    {item.external_ids.LEGO.map((legoId, index) => (
                                                        legoId !== item.lego_id && <span key={index}>{legoId},</span>
                                                    ))}
                                                </p>
                                            )}
                                            <p>{item.title.length > 16 ? item.title.substring(0, 16) + '...' : item.title}</p>
                                            <p>USD: {item.price}</p>
                                            <p>IO Color: {item.io_colour_id}</p>
                                            <p>{item.go_bricks_id}</p>
                                        </div>
                                        <ul className="wish-bx">
                                            <li>
                                                <a className="info-btn" href={`https://mocbrickstore.com/variants/${item.variant_id}`} target="_blank" rel="noopener noreferrer">
                                                    <i className="fa fa-info"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <Link className="info-btn" to={""} onClick={() => handleAddToCart(item)}>
                                                    <i className="fa fa-shopping-cart"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </React.Fragment>
            )}
        </LoadingOverlay>
    )


}

export default BrickSearchResult;