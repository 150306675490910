import { gql } from '@apollo/client';

export const GET_GOBRICKS = gql`
query GetGobricks($search: String!, $page: Int!, $limit: Int!)  {
    GetGobricks(
        search: $search
        page: $page
        limit: $limit
      ) {
        currentPage
        totalPages
        gobricks {
            _id
            title
            body_html
            product_type
            created_at
            handle
            updated_at
            published_at
            tags
            options
            status
            variant_id
            product_id
            price
            sku
            grams
            image_id
            weight
            image_src
            go_bricks_id
            go_bricks_color_id
            lego_id
            lego_color_id
            io_colour_id
            ldraw_colour_id
            external_ids {
              BrickLink
              BrickOwl
              LEGO
              Optionals
            }
        }
    }
  }
`;

export const GET_GOBRICKS_WITH_PART = gql`
query GetGobricksWithParts($parts: [String!], $page: Int!, $limit: Int!) {
  GetGobricksWithParts(
    parts: $parts
    page: $page
    limit: $limit
  ) {
    currentPage
    totalPages
    notFoundParts
    gobricks {
      _id
      title
      body_html
      product_type
      created_at
      handle
      updated_at
      published_at
      tags
      options
      status
      variant_id
      product_id
      price
      sku
      grams
      image_id
      weight
      image_src
      go_bricks_id
      go_bricks_color_id
      lego_color_id
      ldd_colour_id
      ldd_colour_name
      rebrickable_colour_name
      ldraw_colour_id
      io_colour_id
      lego_id
      lego_colour_id
      external_ids {
        BrickLink
        BrickOwl
        LEGO
        Optionals
      }
    }
    legobricks {
      _id
      part_num
      name
      part_cat_id
      part_url
      part_img_url
      print_of
      io_colour_id
      ldraw_colour_id
    }
  }
}
`;

export const GET_GOBRICKS_WITH_RB_CSV_PART = gql`
query GetGobricksWithRbCsvParts($parts: [String!], $colorType: String $page: Int!, $limit: Int!) {
  GetGobricksWithRbCsvParts(
    parts: $parts
    colorType: $colorType
    page: $page
    limit: $limit
  ) {
    currentPage
    totalPages
    notFoundParts
    gobricks {
      _id
      title
      product_type
      created_at
      handle
      updated_at
      published_at
      tags
      options
      status
      variant_id
      product_id
      price
      sku
      grams
      image_id
      weight
      image_src
      go_bricks_id
      go_bricks_color_id
      lego_color_id
      ldd_colour_id
      ldd_colour_name
      rebrickable_colour_name
      ldraw_colour_id
      io_colour_id
      lego_id
      lego_colour_id
      external_ids {
        BrickLink
        BrickOwl
        LEGO
        Optionals
      }
      quantity
    }
    legobricks {
      _id
      part_num
      name
      part_cat_id
      part_url
      part_img_url
      print_of
      io_colour_id
      ldraw_colour_id
      quantity
    }
    optionalGobricks {
      _id
      title
      product_type
      created_at
      handle
      updated_at
      published_at
      tags
      options
      status
      variant_id
      product_id
      price
      sku
      grams
      image_id
      weight
      image_src
      go_bricks_id
      go_bricks_color_id
      lego_color_id
      ldd_colour_id
      ldd_colour_name
      rebrickable_colour_name
      ldraw_colour_id
      io_colour_id
      lego_id
      lego_colour_id
      external_ids {
        BrickLink
        BrickOwl
        LEGO
        Optionals
      }
    }
  }
}
`;