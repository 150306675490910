import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
//Import Env
import { hostPath } from '../env';

//Import Session
import { store } from '../store/store';

//Import Env
import { DEBUG } from '../env';

const apiUrl = hostPath() + 'api';
const httpLink = createHttpLink({
  uri: apiUrl
});

const cache = new InMemoryCache({
  resultCaching: false,
});

const authLink = setContext((_, { headers }) => {
  if (DEBUG() === true) console.log('cache.reset()');
  var token = "";
  const state = store.getState();
  if (state) {
    token = state.auth.auth.idToken;
    if (DEBUG() === true) console.log('accessToken:' + token);
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      }
    }
  }
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: cache,
  queryDeduplication: false,
  defaultOptions: defaultOptions
});

export default client;