import React from 'react';
//import {Link} from 'react-router-dom';
//import { TabContent, TabPane } from 'reactstrap';
//import classnames from 'classnames';
import { Tab, Nav } from 'react-bootstrap';

//Context
import { useCart } from '../../contexts/CartContext';

import BrickSearchResult from './../Pages/BrickSearchResult';
import BrickSearchCart from './../Pages/BrickSearchCart';

import BrickCsvResult from './../Pages/BrickCsvResult';
import BrickNotInGobricks from './../Pages/BrickNotInGobricks';
import BrickTotalInCart from './../Pages/BrickTotalInCart';
import BrickCheckout from './../Pages/BrickCheckout'
import ReactGA from 'react-ga4';

function TabserviceMocBrick({ searchInput, setSearchResult, activeTab, setActiveTab, csvFile, fileName, colorType }) {

    const { searchResultItemsCount, searchInCartItemsCount, csvFileItemsCount, notFoundItemsCount } = useCart();

    const handleTabSelect = (key) => {
        setActiveTab(key);  // Update activeTab state when a tab is selected

        ReactGA.send({ hitType: "pageview", page: "/home", title: key });

        ReactGA.event({
            category: 'User',
            action: 'Tab' + key
          });
    };

    return (
        <>
            <Tab.Container defaultActiveKey="Search" activeKey={activeTab} onSelect={handleTabSelect}>
                <div className="site-filters clearfix center m-b40 listing-filters ">
                    <Nav as="ul" className="filters">
                        <Nav.Item as="li" className="btn site-button-link">
                            <Nav.Link eventKey="Search" >
                                <span>
                                    <i className="la la-search"></i>Search Results
                                    <span className="badge bg-secondary ml-2 text-white"
                                        style={{ minWidth: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        {searchResultItemsCount}</span>
                                </span>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" className="btn site-button-link">
                            <Nav.Link eventKey="Cart">
                                <span>
                                    <i className="la la-cart-arrow-down"></i>Cart from Search
                                    <span className="badge bg-primary ml-2 text-white"
                                        style={{ minWidth: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        {searchInCartItemsCount}</span>
                                </span>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" className="btn site-button-link">
                            <Nav.Link eventKey="Csv_gobrick">
                                <span><i className="la la-cart-arrow-down"></i>CSV Uploaded
                                    <span className="badge bg-primary ml-2 text-white"
                                        style={{ minWidth: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        {csvFileItemsCount}</span>
                                </span>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" className="btn site-button-link">
                            <Nav.Link eventKey="Not_in_gobrick">
                                <span><i className="la la-question-circle"></i>Not available
                                    <span className="badge bg-secondary ml-2"
                                        style={{ minWidth: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                    >
                                        {notFoundItemsCount}</span>
                                </span>
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item as="li" className="btn site-button-link">
                            <Nav.Link eventKey="Total"><span><i className="la la-cart-arrow-down"></i>Total in Cart
                                <span className="badge bg-primary ml-2 text-white"
                                    style={{ minWidth: '20px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                >
                                    {searchInCartItemsCount + csvFileItemsCount}</span>
                            </span>
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>

                    <BrickCheckout />

                </div>
                <div className="clearfix">
                    <Tab.Content>
                        <Tab.Pane eventKey="Search">
                            <BrickSearchResult inputFromSearch={searchInput} setActiveTab={setActiveTab} />
                        </Tab.Pane>
                        <Tab.Pane eventKey="Cart">
                            <BrickSearchCart />
                        </Tab.Pane>
                        <Tab.Pane eventKey="Csv_gobrick">
                            <BrickCsvResult csvFileName={fileName} csvFile={csvFile} colorType={colorType}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="Not_in_gobrick">
                            <BrickNotInGobricks setActiveTab={setActiveTab} colorType={colorType}/>
                        </Tab.Pane>
                        <Tab.Pane eventKey="Total">
                            <BrickTotalInCart />
                        </Tab.Pane>
                    </Tab.Content>
                </div>
            </Tab.Container>
        </>
    )
}
export default TabserviceMocBrick;