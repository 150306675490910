import React from 'react';
import { Link } from 'react-router-dom';
import { useCart } from '../../contexts/CartContext';


function BrickNotInGobricks({ setActiveTab, colorType }) {
    const { noInGobrickItems, optionalGobrickItems, addToCart } = useCart();

    const handleOpenExternalWebsite = (url) => {
        // Check if the URL is provided and is a string
        if (url && typeof url === 'string') {
            // Open the URL in a new tab
            window.open(url, '_blank');
        }
    };

    const handleAddToCart = (item) => {
        addToCart(item, "search");
        setActiveTab("Total");
    };

    return (
        <div className="page-content bg-white">
            <div className="content-block">
                <div className="container">
                    {optionalGobrickItems && optionalGobrickItems.length > 0 && (
                        <p><strong>Optional</strong></p>)}
                    <div className="row">
                        {/* Check if gobricks exists before mapping */}
                        {optionalGobrickItems && optionalGobrickItems.map((item, index) => (
                            <div className="col-lg-2 col-md-4 col-sm-4 col-6 m-b30" key={index}>
                                <div className="listing-bx overlap">
                                    <div className="listing-media">
                                        {/* Use image_src from gobricks for the image */}
                                        <img
                                            src={item.image_src}
                                            alt=""
                                            onError={(e) => { e.target.onerror = null; e.target.src = 'https://moc-parts.web.app/gobricks/images/no_image.png'; }}
                                        />
                                    </div>
                                    <div className="listing-info">
                                        {!item.lego_id.includes("nan") && (
                                            <p>
                                                <strong>{item.lego_id}</strong>
                                            </p>
                                        )}
                                        {item.external_ids && item.external_ids.Optionals && item.external_ids.Optionals.length > 0 && (
                                            <p>
                                                {item.external_ids.Optionals.map((legoId, index) => (
                                                    legoId !== item.lego_id && <span key={index}>{legoId},</span>
                                                ))}
                                            </p>
                                        )}
                                        <p>{item.title.length > 16 ? item.title.substring(0, 16) + '...' : item.title}</p>
                                        <p>USD: {item.price}</p>
                                        {colorType === 'LDraw' && (
                                            <p>LDraw Color: {item.ldraw_colour_id}</p>
                                        )}
                                        {colorType === 'io' && (
                                            <p>IO Color: {item.io_colour_id}</p>
                                        )}
                                        <p>{item.go_bricks_id}</p>
                                    </div>
                                    <ul className="wish-bx">
                                        <li>
                                            <a className="info-btn" href={`https://mocbrickstore.com/variants/${item.variant_id}`} target="_blank" rel="noopener noreferrer">
                                                <i className="fa fa-info"></i>
                                            </a>
                                        </li>
                                        <li>
                                            <Link className="info-btn" to={""} onClick={() => handleAddToCart(item)}>
                                                <i className="fa fa-shopping-cart"></i>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
            <div className="content-block">
                <div className="container">
                    {noInGobrickItems && noInGobrickItems.length > 0 && (
                        <p><strong>Not Available</strong></p>)}
                    <div className="row">
                        {noInGobrickItems && noInGobrickItems.map((item, index) => (
                            <div className="col-lg-2 col-md-4 col-sm-4 col-6 m-b30" key={index}>
                                <div className="listing-bx overlap-gray">
                                    <div className="listing-media">
                                        {/* Use image_src from gobricks for the image */}
                                        <img
                                            src={item.part_img_url} alt="" style={{ padding: '40px' }}
                                            onError={(e) => { e.target.onerror = null; e.target.src = 'https://moc-parts.web.app/gobricks/images/no_image.png'; }}
                                        />
                                    </div>
                                    <div className="listing-info">
                                        {/* <p>{item.name}</p> */}
                                        <p><strong>{item.part_num}</strong></p>
                                        {!item.part_num && item.external_ids && item.external_ids.LEGO && item.external_ids.LEGO.length > 0 && (
                                            item.external_ids.LEGO.map((legoId, index) => (
                                                <p key={index}>{legoId}</p>
                                            ))
                                        )}
                                        <p>{item.name.length > 16 ? item.name.substring(0, 16) + '...' : item.name}</p>
                                        <p>Quantity: {item.quantity}</p>
                                        {colorType === 'LDraw' && (
                                            <p>LDraw Color: {item.ldraw_colour_id}</p>
                                        )}
                                        {colorType === 'io' && (
                                            <p>IO Color: {item.io_colour_id}</p>
                                        )}
                                    </div>
                                    {item.part_url && (
                                        <ul className="wish-bx">
                                            <li>
                                                <Link className="info-btn" to={""} onClick={() => handleOpenExternalWebsite(item.part_url)}>
                                                    <i className="fa fa-external-link"></i>
                                                </Link>
                                            </li>
                                        </ul>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BrickNotInGobricks;