import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
//Import LoadingOverlay 
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader'
//Context
import { useCart } from '../../contexts/CartContext';

//Import graphql
import graphqlClient from '../../graphql/index';
import { GET_GOBRICKS_WITH_RB_CSV_PART } from "../../graphql/gobricksQueries";

//Import Env
import { DEBUG } from '../../env';

const numberOfItemPerPage = 5000;

function BrickCsvResult({ csvFileName, csvFile, colorType }) {

    const [currentPage, setCurrentPage] = useState(1);
    const [limit] = useState(numberOfItemPerPage);
    const [totalPage, setTotalPage] = useState(1);
    const [fileName, setFileName] = useState("");
    const loadingRef = useRef(null);
    const [loading, setLoading] = useState(false);

    const { addAllToCart, addNotFoundBricks, addNotFoundParts, addOptionalGoBricks, csvFileItems, clearAllBricks, allCartItems } = useCart();

    const GetGobricksWithRbCsvParts = useCallback(async (page) => {

        if (csvFileName.length < 3 || fileName === csvFileName) {
            return;
        }
        setFileName(csvFileName);
        if (!csvFile) {
            if (DEBUG() === true) console.log("CSV file contents are undefined");
            return;
        } else {
            if (DEBUG() === true) console.log("CSV file:" + csvFile);
        }

        clearAllBricks();

        const parts = await extractParts(csvFile);
        if (DEBUG() === true) console.log('GET_GOBRICKS_WITH_RB_CSV_PART csvFileName:' + csvFileName + " csvFileContents:" + parts);
        //const parts = ["99780-0", "78258-0", "87994-0", "99207-0", "288999-0"];
        ReactGA.event({
            category: 'User',
            action: 'Uploaded:' + csvFileName,
            label: parts
        });
        setLoading(true);
        if (loadingRef.current) {
            loadingRef.current.scrollIntoView({ behavior: 'smooth' });
        }
        await graphqlClient
            .query({
                query: GET_GOBRICKS_WITH_RB_CSV_PART,
                variables: { parts: parts, colorType: colorType, page: page, limit: limit },
                fetchPolicy: "no-cache"
            })
            .then(result => {
                setLoading(false);
                if (result.errors && result.errors.length > 0) {
                    const errMessage = result.errors[0].message;
                    if (DEBUG() === true) console.log("err" + errMessage);
                } else {
                    if (DEBUG() === true) console.log("getGobricks result currentPage:" + result.data.GetGobricksWithRbCsvParts.currentPage + ' totalPages:' + result.data.GetGobricksWithRbCsvParts.totalPages);
                    setCurrentPage(result.data.GetGobricksWithRbCsvParts.currentPage);
                    setTotalPage(result.data.GetGobricksWithRbCsvParts.totalPages);
                    // Add all items to the cart
                    addNotFoundParts(result.data.GetGobricksWithRbCsvParts.notFoundParts, colorType);
                    addNotFoundBricks(result.data.GetGobricksWithRbCsvParts.legobricks);
                    addOptionalGoBricks(result.data.GetGobricksWithRbCsvParts.optionalGobricks);
                    addAllToCart(result.data.GetGobricksWithRbCsvParts.gobricks, "csv");
                }
            })
            .catch(err => {
                setLoading(false);
                if (DEBUG() === true) console.log("err" + err);
            });
    }, [csvFileName, fileName, csvFile, colorType, clearAllBricks, limit, addAllToCart, addNotFoundParts, addNotFoundBricks, addOptionalGoBricks]);

    const extractParts = (csvFile) => {
        if (!csvFile) {
            // Handle the case where csvFileContents is undefined or null
            //console.error('CSV file contents are undefined or null');
            return [];
        }
        const rows = csvFile.split('\n');
        const parts = rows.slice(1) // Skip the header row
            .map(row => {
                const [partNumber, color, quantity] = row.split(',');
                //console.error('partNumber:' + partNumber + "-" + color + "-" + quantity);
                return `${partNumber}-${color}-${quantity}`;
            })
            .filter(part => part && !part.includes('undefined')); // Filter out empty rows and rows with 'undefined'
        return parts;
    };

    // Effect Hook Similar to componentDidMount and componentDidUpdate
    useEffect(() => {
        GetGobricksWithRbCsvParts(currentPage);
    }, [GetGobricksWithRbCsvParts, currentPage]);

    return (
        <div>
            <div className="container">
                <pre ref={loadingRef}>
                    {csvFileName};
                    {allCartItems.length === 0 ? "Processing, may need more than one minute." : `${allCartItems.length} types`}
                </pre>
            </div>
            <LoadingOverlay
                active={loading === true}
                spinner={<BounceLoader />}
            >
                {loading ? (
                    // Display loading indicator
                    <div className="content-block">
                        <div className="section-full bg-img-fix bg-white content-inner">
                            <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="box-title">Loading...</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    // Render content when not loading
                    <React.Fragment>
                        <div className="container">
                            <div className="row">
                                {/* Check if gobricks exists before mapping */}
                                {csvFileItems &&
                                    csvFileItems.map((item, index) => (
                                        <div
                                            className="col-lg-2 col-md-4 col-sm-4 col-6 m-b30"
                                            key={index}
                                        >
                                            <div className="listing-bx overlap">
                                                <div className="listing-media">
                                                    {/* Use image_src from gobricks for the image */}
                                                    <img
                                                        src={item.image_src}
                                                        alt=""
                                                        onError={(e) => { e.target.onerror = null; e.target.src = 'https://moc-parts.web.app/gobricks/images/no_image.png'; }}
                                                    />
                                                </div>
                                                <div className="listing-info">
                                                    {/* Use a relevant property from gobricks for the title */}
                                                    {/* <p>{item.title}</p> */}
                                                    {!item.lego_id.includes("nan") && (
                                                        <p>
                                                            <strong>{item.lego_id}</strong>
                                                        </p>
                                                    )}
                                                    {item.external_ids && item.external_ids.LEGO && item.external_ids.LEGO.length > 0 && (
                                                        <p>
                                                            {item.external_ids.LEGO.map((legoId, index) => (
                                                                legoId !== item.lego_id && <span key={index}>{legoId},</span>
                                                            ))}
                                                        </p>
                                                    )}
                                                    <p>USD: {item.price}</p>

                                                    {colorType === 'LDraw' && (
                                                        <p>LDraw Color: {item.ldraw_colour_id}</p>
                                                    )}
                                                    {colorType === 'io' && (
                                                        <p>IO Color: {item.io_colour_id}</p>
                                                    )}
                                                    <p>
                                                        {item.go_bricks_id}
                                                    </p>
                                                    <p>Quantity: {item.quantity}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </React.Fragment>
                )
                }
            </LoadingOverlay >
        </div>
    );
}

export default BrickCsvResult;