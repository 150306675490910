import React from 'react';
import { useCart } from '../../contexts/CartContext';
import ReactGA from 'react-ga4';

function BrickCheckout() {
    const { allCartItems } = useCart();
    const storeUrl = 'https://mocbrickstore.com';

    // Function to split items into chunks
    const chunkItems = (items, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < items.length; i += chunkSize) {
            chunks.push(items.slice(i, i + chunkSize));
        }
        return chunks;
    };

    // Function to create checkout URL for a chunk of items
    const createCheckoutUrl = (items) => {
        const cartParams = items.map(item => `${item.variant_id}:${item.quantity}`).join(',');
        return `${storeUrl}/cart/${cartParams}?storefront=true`;
    };

    // Function to handle checkout
    const handleCheckout = (items) => {
        ReactGA.event({
            category: 'User',
            action: 'Checkout'
        });
        const checkoutUrl = createCheckoutUrl(items);
        window.open(checkoutUrl, '_blank');
    };

    // Split allCartItems into chunks of 200
    const itemChunks = chunkItems(allCartItems, 200);

    // Function to calculate total cost
    const totalCost = (items) => {
        const cost = items.reduce((total, item) => {
            return total + (parseFloat(item.price) * item.quantity);
        }, 0.0);
        return cost.toFixed(2);
    };

    return (
        <div>
            <br />
            {itemChunks.map((chunk, index) => (
                <button
                    key={index}
                    className="site-button text-uppercase checkout-button"
                    onClick={() => handleCheckout(chunk)}
                    disabled={chunk.length === 0}
                    style={{ backgroundColor: chunk.length === 0 ? 'grey' : '', margin: '10px' }}>
                    <i className="fa m-r5 fa-shopping-cart"></i>
                    {itemChunks.length > 1 ? `Checkout Chunk ${index + 1}` : 'Checkout'} (USD {totalCost(chunk)})
                </button>
            ))}
            {itemChunks.length > 1 && (
            <div style={{ marginTop: '10px' }}>
                <strong>Total: USD {totalCost(allCartItems)} - maximum 200 types per checkout. Please use above buttons checkout one by one.</strong>
            </div>
        )}
        </div>
    );
}

export default BrickCheckout;
