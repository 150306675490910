import React, { useRef } from 'react';

function CsvUpload({ setFile }) {
    // Create a reference to the hidden file input element
    const hiddenFileInput = useRef(null);

    const handleChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFile(selectedFile);
            // You can further process the file here if needed
        }
    };

    const handleClick = event => {
        event.preventDefault();  // Prevent default form submission
        hiddenFileInput.current.click();   
      };

    return (
        <>
            <button
                className="site-button text-uppercase"
                onClick={handleClick}
            >
                <i className="fa m-r5 fa-upload"></i>.csv or .lrd or .xml
            </button>
            <input
                type="file"
                onChange={handleChange}
                ref={hiddenFileInput}
                style={{ display: 'none' }}
            />
        </>
    );
}

export default CsvUpload;
