import React from 'react';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';
import { useCart } from '../../contexts/CartContext';


function BrickSearchCart() {
    const { searchCartItems, addToCart, removeFromCart } = useCart();

    const handleAddToCart = (item) => {
        ReactGA.event({
            category: 'User',
            action: 'addToCart' + item.go_bricks_id
        });
        addToCart(item, "search");
    };

    const handleRemoveFromCart = (item) => {
        removeFromCart(item, "search");
    };

    return (
        <div className="container">
            <div className="row">
                {searchCartItems.map((item, index) => (
                    <div className="col-lg-2 col-md-4 col-sm-4 col-6 m-b30" key={index}>
                        <div className="listing-bx overlap-blue">
                            <div className="listing-media">
                                {/* Use image_src from gobricks for the image */}
                                <img
                                    src={item.image_src}
                                    alt=""
                                    onError={(e) => { e.target.onerror = null; e.target.src = 'https://moc-parts.web.app/gobricks/images/no_image.png'; }}
                                />
                            </div>
                            <div className="listing-info">
                                {/* <p>{item.title}</p> */}
                                {!item.lego_id.includes("nan") && (
                                    <p>
                                        <strong>{item.lego_id}</strong>
                                    </p>
                                )}
                                {item.external_ids && item.external_ids.LEGO && item.external_ids.LEGO.length > 0 && (
                                    <p>
                                        {item.external_ids.LEGO.map((legoId, index) => (
                                            legoId !== item.lego_id && <span key={index}>{legoId},</span>
                                        ))}
                                    </p>
                                )}

                                {/* {item.external_ids && item.external_ids.LEGO && item.external_ids.LEGO.length > 0 && (
                                    <p>
                                        {item.external_ids.LEGO.map((legoId, index) => (
                                            legoId !== item.lego_id && <span key={index}>{legoId},</span>
                                        ))}
                                    </p>
                                )} */}
                                <p>USD: {item.price}</p>
                                <p>Quantity: {item.quantity}</p>
                                <p>IO Color: {item.io_colour_id}</p>
                                <p>{item.go_bricks_id}</p>
                            </div>
                            <ul className="wish-bx">
                                <li>
                                    <Link className="info-btn" to={""} onClick={() => handleAddToCart(item)}>
                                        <i className="fa fa-plus"></i>
                                    </Link>
                                </li>

                                <li>
                                    <Link className="info-btn" to={""} onClick={() => handleRemoveFromCart(item)}>
                                        <i className="fa fa-minus"></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default BrickSearchCart;