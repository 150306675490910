import React from 'react';
import {Link} from 'react-router-dom';
//import emailjs from 'emailjs-com';
//import swal from "sweetalert";	
import Mailchimp from './Mailchimp';


const Footer = () => {
	/* const form = useRef();
	const sendEmail = (e) => {
		e.preventDefault();
		//emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_USER_ID')
		emailjs.sendForm('service_zubfdvh', 'template_iy1pb0b', e.target, 'user_FvNIKVJ7om2PphifhzMm2')
		  .then((result) => {
			  console.log(result.text);
		  }, (error) => {
			  console.log(error.text);
		  });
		  e.target.reset()
		  swal('Good job!', 'Subscribe', "success");
	};
 */
	return(
		<footer className="site-footer">
			
			<div className="footer-bottom">
				<div className="container">
					<div className="row">
						<div className="col-lg-12 text-center">
							<span className="fbottom-like"> Power By </span> <a target='_blank' rel='noopener noreferrer' href="https://www.mocbrickstore.com">MOC Brick Store</a>
						</div>
					</div>
				</div>
			</div>
		</footer>
	)
}

export default Footer;