import React from 'react';

import { useCart } from '../../contexts/CartContext';


function BrickTotalInCart() {
    const { allCartItems } = useCart();

    return (
        <div className="container">
            <div className="row">
                {allCartItems.map((item, index) => (
                    <div className="col-lg-2 col-md-4 col-sm-4 col-6 m-b30" key={index}>
                        <div className="listing-bx overlap">
                            <div className="listing-media">
                                {/* Use image_src from gobricks for the image */}
                                <img
                                    src={item.image_src}
                                    alt=""
                                    onError={(e) => { e.target.onerror = null; e.target.src = 'https://moc-parts.web.app/gobricks/images/no_image.png'; }}
                                />
                            </div>
                            <div className="listing-info">
                                {/* <p>{item.title}</p> */}
                                {!item.lego_id.includes("nan") && (
                                    <p>
                                        <strong>{item.lego_id}</strong>
                                    </p>
                                )}
                                {item.external_ids && item.external_ids.LEGO && item.external_ids.LEGO.length > 0 && (
                                    <p>
                                        {item.external_ids.LEGO.map((legoId, index) => (
                                            legoId !== item.lego_id && <span key={index}>{legoId},</span>
                                        ))}
                                    </p>
                                )}
                                <p>USD: {item.price}</p>
                                <p>Quantity: {item.quantity}</p>
                                <p>IO Color: {item.io_colour_id}</p>
                                <p>{item.go_bricks_id}</p>
                                {/* <p>variant: {item.variant_id}</p> */}
                            </div>
                            <ul className="wish-bx">
                                <li>
                                    <a className="info-btn" href={`https://mocbrickstore.com/variants/${item.variant_id}`} target="_blank" rel="noopener noreferrer">
                                        <i className="fa fa-info"></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default BrickTotalInCart;