import dotenv from 'dotenv';
dotenv.config();
//Very important 
const env = "PRO_ENV"; //DEV_ENV //STAG_ENV //PRO_ENV

export let envName = () => {
    if (env === "DEV_ENV") {
        return 'development';
    }

    if (env === "STAG_ENV") {
        return 'staging';
    }

    if (env === "PRO_ENV") {
        return 'production';
    }

    return '';
}

export let DEBUG = () => {
    if (env === "DEV_ENV" || env === "STAG_ENV") {
        return true;
    }

    return false;
}

export let appName = () => {
    return process.env.REACT_APP_NAME;
}

export let appVersion = () => {
    return process.env.REACT_APP_VERSION;
}

export let hostPath = () => {
    if (env === "DEV_ENV") {
        console.log("process.env current environment:" + env + process.env.REACT_APP_DEV_ENV_URL);
        return process.env.REACT_APP_DEV_ENV_URL;
    }

    if (env === "STAG_ENV") {
        console.log("current environment:" + env);
        return process.env.REACT_APP_STAG_ENV_URL;
    }

    if (env === "PRO_ENV") {
        console.log("current environment:" + env);
        return process.env.REACT_APP_PRO_ENV_URL;
    }

    return '';
}