import React, { createContext, useContext, useReducer } from 'react';

const CartContext = createContext();

const initialState = {
    searchItems: [],
    cartItems: [],
    optionalItems: [],
    notFoundBricks: [],
    notFoundParts:[]
};

const cartReducer = (state, action) => {
    switch (action.type) {
        case 'ADD_ALL_SEARCH':
            const { bricks: newSearchBricks } = action.payload;

            if (newSearchBricks === null) {
                return {
                    ...state,
                    searchItems: [],
                };
            }

            let updatedSearchItems = newSearchBricks.map((newBrick) => ({ ...newBrick }));

            return {
                ...state,
                searchItems: updatedSearchItems,
            };
        case 'ADD_TO_CART':
            const newItem = action.payload;
            const existingItem = state.cartItems.find(
                (i) => i.variant_id === newItem.variant_id
            );
            if (existingItem) {
                return {
                    ...state,
                    cartItems: state.cartItems.map((i) =>
                        i.variant_id === newItem.variant_id
                            ? { ...i, quantity: i.quantity + 1 }
                            : i
                    ),
                };
            } else {
                return {
                    ...state,
                    cartItems: [{ ...newItem, quantity: 1 }, ...state.cartItems],
                };
            }
        case 'REMOVE_FROM_CART':
            const { item: removedItem, itemType } = action.payload;
            const itemInCart = state.cartItems.find(
                (i) => i.variant_id === removedItem.variant_id && i.type === itemType
            );
            if (itemInCart) {
                if (itemInCart.quantity > 1) {
                    // If the quantity is greater than 1, decrement the quantity
                    return {
                        ...state,
                        cartItems: state.cartItems.map((i) =>
                            i.variant_id === removedItem.variant_id && i.type === itemType
                                ? { ...i, quantity: i.quantity - 1 }
                                : i
                        ),
                    };
                } else {
                    // If the quantity is 1, remove the item from the cart
                    return {
                        ...state,
                        cartItems: state.cartItems.filter(
                            (i) => i.variant_id !== removedItem.variant_id || i.type !== itemType
                        ),
                    };
                }
            } else {
                // If the item is not found in the cart, return the current state
                return state;
            }
        case 'ADD_ALL_TO_CART':
            const { items: newItems, type } = action.payload;
            let updatedCartItems = [...state.cartItems];
            if (newItems) {
                newItems.forEach((newItem) => {
                    const quantity = newItem.quantity ? newItem.quantity : 1;
                    updatedCartItems.push({ ...newItem, quantity, type });
                });

                return {
                    ...state,
                    cartItems: updatedCartItems,
                };
            } else {
                return {
                    ...state,
                    cartItems: updatedCartItems,
                };
            }
        case 'ADD_NOT_FOUND_BRICKS':
            const { bricks: newBricks } = action.payload;
            let updatedNotFoundBricks = [...state.notFoundBricks];

            if (newBricks) {
                newBricks.forEach((newBrick) => {
                    const quantity = newBrick.quantity ? newBrick.quantity : 1;
                    updatedNotFoundBricks.push({ ...newBrick, quantity });
                });
            }
            return {
                ...state,
                notFoundBricks: updatedNotFoundBricks,
            };
        case 'ADD_NOT_FOUND_PARTS':
            const { bricks: newParts, colorType } = action.payload;
            let updatedNotFoundBricksWithParts = [...state.notFoundBricks];

            if (newParts) {
                newParts.forEach((newPartString) => {
                    const [partId, colorId, quantity] = newPartString.split('-');
                    const newBrick = {
                        name:'',
                        part_num: partId,
                        part_img_url:'https://moc-parts.web.app/gobricks/images/no_image.png',
                        quantity: parseInt(quantity, 10)
                    };
                    if (colorType === 'io') {
                        newBrick.io_colour_id = colorId;
                    } else if (colorType === 'LDraw') {
                        newBrick.ldraw_colour_id = colorId;
                    }
                    updatedNotFoundBricksWithParts.push(newBrick);
                });
            }
            return {
                ...state,
                notFoundBricks: updatedNotFoundBricksWithParts,
            };
        case 'ADD_OPTIONAL_GO_BRICKS':
            const { bricks: newOptionalBricks } = action.payload;
            if (newOptionalBricks === null) {
                return {
                    ...state,
                    searchItems: [],
                };
            }

            let updatedOptionalItems = newOptionalBricks.map((newBrick) => ({ ...newBrick }));

            return {
                ...state,
                optionalItems: updatedOptionalItems,
            };
        case 'CLEAR_ALL':
            return {
                ...state,
                cartItems: [],
                notFoundBricks: [],
                optionalItems: [],
                notFoundParts:[],
            };
        default:
            return state;
    }
};

export const CartProvider = ({ children }) => {
    const [state, dispatch] = useReducer(cartReducer, initialState);

    const addAllFoundBricks = (bricks = []) => {
        dispatch({ type: 'ADD_ALL_SEARCH', payload: { bricks } });
    };

    const addToCart = (item, type) => {
        const newItem = { ...item, type };
        dispatch({ type: 'ADD_TO_CART', payload: newItem });
    };

    const removeFromCart = (item, type) => {
        dispatch({ type: 'REMOVE_FROM_CART', payload: { item, itemType: type } });
    };

    const addAllToCart = (items, type) => {
        dispatch({ type: 'ADD_ALL_TO_CART', payload: { items, type } });
    };

    const addNotFoundBricks = (bricks = []) => {
        dispatch({ type: 'ADD_NOT_FOUND_BRICKS', payload: { bricks } });
    };

    const addNotFoundParts = (bricks = [], colorType) => {
        dispatch({ type: 'ADD_NOT_FOUND_PARTS', payload: { bricks, colorType } });
    };

    const addOptionalGoBricks = (bricks = []) => {
        dispatch({ type: 'ADD_OPTIONAL_GO_BRICKS', payload: { bricks } });
    };

    const clearAllBricks = () => {
        dispatch({ type: 'CLEAR_ALL' });
    };

    const { searchItems, cartItems, notFoundBricks, notFoundParts, optionalItems } = state;

    const searchInCartItemsCount = cartItems.reduce(
        (count, item) => (item.type === 'search' ? count + (item.quantity || 1) : count),
        0
    );

    const csvFileItemsCount = cartItems.reduce(
        (count, item) => (item.type === 'csv' ? count + (item.quantity || 1) : count),
        0
    );

    const notFoundItemsCount = notFoundBricks.reduce(
        (count, item) => (count + (item.quantity || 1)),
        0
    );

    const searchResultItemsCount = searchItems ? searchItems.length : 0;

    const searchCartItems = cartItems.filter(item => item.type === 'search');

    const csvFileItems = cartItems.filter(item => item.type === 'csv');

    const noInGobrickItems = notFoundBricks;

    const optionalGobrickItems = optionalItems;

    const allCartItems = cartItems;

    const noInGobrickAndRbItems = notFoundParts;

    return (
        <CartContext.Provider
            value={{
                cartItems: state.cartItems,
                notFoundBricks: state.notFoundBricks,
                addAllFoundBricks,
                addToCart,
                addAllToCart,
                removeFromCart,
                addNotFoundBricks,
                addNotFoundParts,
                addOptionalGoBricks,
                clearAllBricks,
                searchResultItemsCount,
                searchInCartItemsCount,
                csvFileItemsCount,
                notFoundItemsCount,
                searchCartItems,
                csvFileItems,
                noInGobrickItems,
                optionalGobrickItems,
                allCartItems,
                noInGobrickAndRbItems
            }}
        >
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => {
    return useContext(CartContext);
};
